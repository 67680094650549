import { useMemo } from 'react';

import useSharedArtistComponents from '../ItemPageArtist/useSharedArtistComponents';
import { PageSectionTypes } from '~/app/components/ItemPage/sections/types';
import { selectUserCountry } from '~/app/lib/store/session/selectors';
import { SelectedTrack } from '~/app/lib/store/tracks/types';
import { AddonTypes } from '~/app/lib/songwhipApi/types';
import { useSelector } from '~/app/lib/store/redux';
import { useI18n } from '~/app/lib/i18n';

import {
  ItemContext,
  ItemPageLayoutDefinition,
  PresetTypes,
} from '~/app/components/ItemPage/types';

import {
  toStreamLinksSectionPreset,
  toBuyLinksSectionPreset,
} from '~/app/components/ItemPage/sections/ItemLinks/presets';

import { TrackItemContext } from './types';

const DEFAULT_LAYOUT: ItemPageLayoutDefinition['main'] = [
  {
    component: PageSectionTypes.PAGE_TITLE,
  },
  {
    preset: PresetTypes.STREAM_LINKS,
  },
  {
    preset: PresetTypes.BUY_LINKS,
  },
];

const useTrackItemContext = ({
  track,
  withAllLinks,
  originalItemContext,
}: {
  track: SelectedTrack;
  withAllLinks?: boolean;
  originalItemContext?: ItemContext;
}) => {
  const { t } = useI18n();
  const country = useSelector(selectUserCountry);
  const sharedComponents = useSharedArtistComponents(track?.artistId);
  const addons = track.config?.addons || {};

  // show showcase background by default
  addons[AddonTypes.THEME] = {
    showcaseBackground: true,
    ...addons[AddonTypes.THEME],
  };

  return useMemo<TrackItemContext | undefined>(
    () =>
      track && {
        presets: {
          [PresetTypes.STREAM_LINKS]: toStreamLinksSectionPreset({
            title: t('item.actions.streamTrackOn'),
            country: withAllLinks ? 'ALL' : country,
          }),

          [PresetTypes.BUY_LINKS]: toBuyLinksSectionPreset({
            title: t('item.actions.buySongOn'),
          }),
        },

        data: {
          item: track,
          custom: track.config?.objects,
        },

        layout: {
          main: toLayout(track),
        },

        config: {
          showArtistIds: track.config?.showArtistIds || track.artistIds,
        },

        shared: sharedComponents,
        addons,
        originalItemContext,
      },
    [track, originalItemContext]
  );
};

const toLayout = (track: SelectedTrack) => {
  // use custom layout if defined, otherwise use the default layout
  return track.config?.layout?.main || DEFAULT_LAYOUT;
};

export default useTrackItemContext;
